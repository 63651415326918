.eb-text {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  @apply text-emblue-black;
}
/** Colors */
.eb-text.eb-text--color-black {
  @apply !text-emblue-black;
}

.eb-text.eb-text--color-dark {
  @apply !text-emblue-text;
}

.eb-text.eb-text--color-gray {
  @apply !text-emblue-disabled;
}

.eb-text.eb-text--color-light-black {
  @apply !text-emblue-black-light;
}

.eb-text.eb-text--color-white {
  @apply !text-emblue-white;
}

.eb-text.eb-text--color-primary {
  @apply !text-emblue-primary;
}

.eb-text.eb-text--color-secondary {
  @apply !text-emblue-secondary;
}

.eb-text.eb-text--color-tertiary {
  @apply !text-emblue-tertiary;
}

.eb-text.eb-text--color-alert {
  @apply !text-emblue-alert;
}

/** fontSizes */
.eb-text.eb-text--text-sm {
  font-size: 12px;
}

.eb-text.eb-text--text {
  font-size: 14px;
}

.eb-text.eb-text--sub-headline {
  font-size: 16px;
}

.eb-text.eb-text--sub-headline-2 {
  font-size: 18px;
}

.eb-text.eb-text--headline {
  font-size: 20px;
  font-weight: bold;
}

.eb-text.eb-text--title-1 {
  font-size: 24px;
  font-weight: bold;
}

.eb-text.eb-text--title-2 {
  font-size: 30px;
  font-weight: bold;
}

.eb-text.eb-text--title-3 {
  font-size: 36px;
  font-weight: bold;
}

/** link */
.eb-text.eb-text--link {
  @apply cursor-pointer text-emblueBlue;
}

/** weight */

.eb-text.eb-text--ultralight {
  /** cspell:disable */
  @apply font-extralight;
}

.eb-text.eb-text--light {
  @apply font-light;
}

.eb-text.eb-text--normal {
  @apply font-normal;
}

.eb-text.eb-text--medium {
  @apply font-medium;
}

.eb-text.eb-text--semibold {
  @apply font-semibold;
}

.eb-text.eb-text--bold {
  @apply font-bold;
}

.eb-text.eb-text--extrabold {
  @apply font-extrabold;
}

.eb-text.eb-text--black {
  @apply font-black;
}
