@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./Roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("./Roboto/Roboto-Italic.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("./Roboto/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: url("./Roboto/Roboto-BoldItalic.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 250;
  src: url("./Roboto/Roboto-Thin.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 250;
  src: url("./Roboto/Roboto-ThinItalic.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("./Roboto/Roboto-Light.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: url("./Roboto/Roboto-LightItalic.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("./Roboto/Roboto-Medium.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: url("./Roboto/Roboto-MediumItalic.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("./Roboto/Roboto-Black.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  src: url("./Roboto/Roboto-BlackItalic.ttf");
}
