button.primary {
  @apply bg-emblueBlue text-white hover:bg-emblueBlue-dark flex flex-row items-center justify-center focus:ring-[#004DBC];
}

button.secondary {
  @apply bg-emblue-orange text-white hover:bg-emblue-orange-dark flex flex-row items-center justify-center;
}

button.stroked {
  @apply bg-white text-blue-600 border-[1px] border-blue-600 border-solid rounded-[6px] hover:bg-slate-50 hover:font-medium flex flex-row items-center justify-center focus:ring-[#004DBC];
}

button.plain {
  @apply bg-white text-blue-600 border-[1px] border-blue-600 border-solid rounded-[6px] hover:bg-slate-50 hover:font-medium flex flex-row items-center justify-center focus:ring-[#004DBC];
}

button.standard {
  @apply bg-white border-[1px] border-gray-300 border-solid rounded-[6px] hover:bg-slate-50 hover:font-medium flex flex-row items-center justify-center;
}

button.deleted {
  @apply bg-red-400 text-white hover:bg-red-500 flex flex-row items-center justify-center;
}

button.link {
  @apply bg-transparent border-[0px] text-blue-600 hover:font-medium hover:bg-transparent flex flex-row items-center justify-center rounded-[0px];
}
