.wrapper-rules-detail .react-flow__panel.react-flow__attribution.bottom.right {
  display: none;
}

.wrapper-rules-detail .react-flow .react-flow__handle {
  visibility: hidden;
}

.wrapper-rules-detail .react-flow .react-flow__handle-top {
  top: -10px;
}

.wrapper-rules-detail .react-flow .react-flow__handle-bottom {
  bottom: -10px;
}

.wrapper-rules-detail .react-flow .react-flow__edge path,
.wrapper-rules-detail .react-flow__connectionline path {
  stroke-width: 2;
}

.wrapper-rules-detail {
  flex-grow: 1;
  height: 100%;
}
