.eb-select {
  @apply w-full relative;
}

.eb-select .eb-select--select {
  @apply text-left relative w-full cursor-default leading-5 pl-3 pr-8 py-2 border my-1 text-14 border-emblue-borders rounded-md bg-white placeholder-[#A7B1CC] text-[#111827] focus:outline-none focus:ring-emblue-primary focus:border-emblue-primary;
}

.eb-select .eb-select--error {
  @apply border-emblue-alert text-emblue-alert placeholder-red-300 focus:border-red-500 focus:ring-red-500 pr-9;
}

.eb-select--message-error {
  @apply text-base text-emblue-alert;
}

.eb-select--message-info {
  @apply text-base text-emblue-borders;
}

.eb-select .readOnly {
  @apply border-transparent select-none px-0 !important;
}
