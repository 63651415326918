.bg-alert-success {
  @apply bg-[#F2FDF5] border-emblue-success border-l-[3px];
}

.bg-alert-warning {
  @apply bg-[#FEFCEA] border-emblue-passive border-l-[3px];
}

.bg-alert-info {
  @apply bg-[#F0F6FE] border-emblue-primary border-l-[3px];
}

.bg-alert-error {
  @apply bg-[#FCF2F2] border-emblue-detractor border-l-[3px];
}
