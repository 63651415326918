/* ALL Metrics */
.channel-metric__open {
  @apply bg-emblue-success;
}

.channel-metric__click {
  @apply bg-emblue-sleepy;
}

.channel-metric__bounce {
  @apply bg-emblue-alert-hover;
}

.channel-metric__unsubscribe {
  @apply bg-emblue-alert;
}

/* EMAIL & SMS Metrics */
.channel-metric__delivered {
  @apply bg-emblue-often;
}

.channel-metric__delivered-hover {
  @apply bg-emblue-often-hover;
}

/* INBOX Metrics */
.channel-metric__inbox {
  @apply bg-emblue-often;
}
