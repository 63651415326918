.button-group {
  @apply w-full;
}

.button-group > div button {
  @apply !rounded-none;
}

.button-group > div:first-child button {
  @apply !rounded-none !rounded-l-md;
}

.button-group > div:last-child button {
  @apply !rounded-none !rounded-r-md;
}
