.eb-multi-value-input {
  @apply relative w-full;
}

.eb-multi-value-input .eb-multi-value-input--input {
  @apply block leading-5 h-[38px] w-full px-3 py-2 border my-1 text-14 border-emblue-borders rounded-md bg-white placeholder-[#A7B1CC] text-[#111827] focus:outline-none focus:ring-emblue-primary focus:border-emblue-primary;
}

.eb-multi-value-input .eb-multi-value-input--div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.eb-multi-value-input .eb-multi-value-input--error {
  @apply border-emblue-alert text-emblue-alert placeholder-red-300 focus:border-red-500 focus:ring-red-500 pr-9;
}

.eb-multi-value-input .eb-multi-value-input--message-error {
  @apply text-base text-emblue-alert;
}

.eb-multi-value-input .eb-multi-value-input--message-info {
  @apply text-base text-emblue-borders;
}
