.card-content:hover .default-content {
  display: none;
}

.card-content:hover .hover-content {
  display: block;
}

.hover-content {
  display: none;
}

.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
