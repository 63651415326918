.eb-input-search {
  @apply flex flex-row w-full;
}

.eb-input-search input {
  @apply !rounded-r-none !border-r-0;
}

.eb-input-search button {
  @apply !rounded-l-none focus:!ring-transparent;
}
