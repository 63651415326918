.white-content .heading {
  @apply text-emblue-white;
}

.white-content .subheading {
  @apply text-slate-50;
}

.white-content .counter {
  @apply text-emblue-white;
}

.white-content .link {
  @apply text-slate-50;
}
