.gap-none {
  @apply gap-0;
}

.gap-small {
  @apply gap-2;
}

.gap-medium {
  @apply gap-4;
}

.gap-large {
  @apply gap-8;
}

.gap-extra-large {
  @apply gap-10;
}
