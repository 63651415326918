.select-phone-number {
  @apply relative w-full h-[38px] cursor-default leading-5 px-3 py-2 border my-1 text-14 border-emblue-borders rounded-md bg-white placeholder-[#A7B1CC] text-[#111827] focus-within:outline-none focus-within:ring-emblue-primary focus-within:border-emblue-primary;
}

.select-phone-number input {
  @apply focus:outline-none h-full;
}

.select-phone-number.input-error {
  @apply border-emblue-alert text-emblue-alert placeholder-red-300 focus:border-red-500 focus:ring-red-500 pr-9;
}
