.time-input--container {
  position: relative;
  width: auto;
}

.time-input--container input {
  @apply block leading-5 w-full h-[38px] !p-2 my-1 border text-14 border-emblue-borders rounded-md bg-white placeholder-[#A7B1CC] text-[#111827] focus:outline-none focus:ring-emblue-primary focus:border-emblue-primary;
}

.time-input--container [data-placement^='bottom'] {
  padding-top: 5px;
}

.time-input--container div#time-input-group:focus-within > div > div > svg path {
  @apply stroke-emblue-primary;
}

.time-input--error {
  @apply text-base text-emblue-alert;
}

.time-input--container div#time-input-group.time-input--container-error svg path {
  @apply stroke-emblue-alert;
}

.time-input--container div#time-input-group.time-input--container-error input {
  @apply border-[#FF4261] text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500 pr-24 mr-8;
}

.time-input--container div#time-input-group.readOnly input {
  @apply border-transparent select-none px-0 !important;
}

.time-input--container div#time-input-group.input-time-disabled input {
  @apply !border-emblue-secondary !bg-emblue-secondary-hover !text-emblue-gray;
}

.time-icon--input {
  @apply absolute right-3 top-2.5 text-emblue-gray-dark;
}

.react-datepicker__time-list::-webkit-scrollbar {
  width: 5px;
}

.react-datepicker__time-list::-webkit-scrollbar-track {
  background-color: #f4f5fa;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb {
  border-radius: 550px;
  background: #d3ddea;
}

.react-datepicker__header--time--only,
.react-datepicker__triangle {
  @apply hidden;
}

.react-datepicker__time-list-item {
  max-width: 85px;
  margin: 0 auto;
  @apply hover:bg-emblue-primary-light hover:text-emblue-text rounded-lg !important;
}
.time-input--container .react-datepicker-popper {
  @apply w-full !important;
}

.react-datepicker--time-only {
  @apply w-full flex justify-center align-middle !important;
}

.react-datepicker__time-list-item--selected {
  @apply bg-emblue-primary !important;
}

.react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100%;
}
