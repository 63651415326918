/** general class button */
.eb-button {
  @apply inline-flex items-center px-3 py-2 text-14 border border-transparent shadow-sm leading-4 text-center font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100;
}

/** size classes */
.eb-button.eb-button--tiny {
  @apply px-1 py-1 text-base h-[32px];
}

.eb-button.eb-button--small {
  @apply px-3 py-1 text-14 h-[32px];
}

.eb-button.eb-button--medium {
  @apply px-3 py-2 h-[38px];
}

.eb-button.eb-button--large {
  @apply px-4 py-2 h-[42px] text-16;
}

.eb-button.eb-button--extraLarge {
  @apply px-6 py-3 h-[50px] text-16;
}

/** variant color classes */
.eb-button.eb-button--primary {
  @apply bg-emblue-primary text-white hover:bg-emblue-primary-hover items-center justify-center focus:ring-emblue-primary;
}

.eb-button.eb-button--secondary {
  @apply bg-emblue-secondary text-[#364365] hover:bg-emblue-secondary-hover items-center justify-center focus:ring-gray-200;
}

.eb-button.eb-button--tertiary {
  @apply bg-emblue-tertiary text-white hover:bg-emblue-tertiary-hover items-center justify-center focus:ring-emblue-orange;
}

.eb-button.eb-button--destructive {
  @apply bg-emblue-alert text-white hover:bg-red-400 items-center justify-center focus:ring-emblue-alert;
}

/** outline classes */
.eb-button.eb-button--outline--primary {
  @apply bg-transparent text-emblue-primary border-[1px] border-emblue-primary border-solid rounded-[6px] hover:bg-slate-50 hover:font-medium flex flex-row items-center justify-center focus:ring-emblue-primary;
}

.eb-button.eb-button--outline--secondary {
  @apply bg-transparent text-emblue-subheadings border-[1px] border-emblue-gray border-solid rounded-[6px] hover:bg-slate-50 hover:font-medium flex flex-row items-center justify-center focus:ring-emblue-subheadings;
}

.eb-button.eb-button--outline--tertiary {
  @apply bg-transparent text-emblue-tertiary border-[1px] border-emblue-tertiary border-solid rounded-[6px] hover:bg-slate-50 hover:font-medium flex flex-row items-center justify-center focus:ring-emblue-tertiary;
}

.eb-button.eb-button--outline--destructive {
  @apply bg-transparent text-emblue-alert  border-emblue-alert border-solid rounded-[6px] hover:bg-slate-50 hover:font-medium flex flex-row items-center justify-center focus:ring-emblue-alert;
}

.eb-button.eb-button--outline--gray {
  @apply bg-transparent text-emblue-gray-dark border-[1px] border-emblue-gray-dark border-solid rounded-[6px] hover:bg-slate-50 hover:font-medium flex flex-row items-center justify-center focus:ring-emblue-primary;
}

.eb-button.eb-button--standard {
  @apply bg-white border-[1px] border-emblue-borders border-solid rounded-[6px] hover:bg-slate-50 hover:font-medium flex flex-row items-center justify-center;
}

.eb-button.eb-button--link {
  @apply bg-transparent border-[0px] text-emblue-primary hover:font-medium hover:bg-transparent flex flex-row items-center justify-center shadow-none rounded-none focus:ring-transparent focus:ring-offset-0;
}

.eb-button.eb-button--only-icon {
  @apply bg-transparent items-center justify-center shadow-none border-transparent hover:bg-transparent focus:ring-transparent focus:ring-offset-0;
}
