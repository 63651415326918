.stepper-item {
  position: relative;
}

.stepper-item:not(:last-child) {
  @apply mb-4;
}

.stepper-item:not(:last-child) .pre-spacer {
  content: '';
  position: absolute;
  height: 50%;
  width: 2px;
  left: calc(1rem - 1px);
  /* God knows why */
  top: calc(50% + 1rem);
  @apply bg-emblueGreen;
}

.stepper-item.current:not(:last-child) .pre-spacer,
.stepper-item.upcoming:not(:last-child) .pre-spacer {
  content: '';
  position: absolute;
  height: 50%;
  width: 2px;
  left: calc(1rem - 1px);
  /* God knows why */
  top: calc(50% + 1rem);
  @apply bg-gray-300;
}

.stepper-item.upcoming:not(:first-of-type) .spacer {
  content: '';
  position: absolute;
  height: 50%;
  width: 2px;
  left: calc(1rem - 1px);
  /* God knows why */
  bottom: calc(50% + 1rem);
  @apply bg-gray-300;
}

.stepper-item:not(:first-of-type) .spacer {
  content: '';
  position: absolute;
  height: 50%;
  width: 2px;
  left: calc(1rem - 1px);
  /* God knows why */
  bottom: calc(50% + 1rem);
  @apply bg-emblueGreen;
}
