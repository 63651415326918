.eb-badgeText {
  @apply inline-flex items-center rounded px-4 py-1 text-base font-medium;
}

.eb-badgeText.eb-badgeText--colors-primary {
  @apply bg-emblue-primary-light text-emblue-primary;
}

.eb-badgeText.eb-badgeText--colors-secondary {
  @apply bg-emblue-secondary text-emblue-black;
}

.eb-badgeText.eb-badgeText--colors-success {
  @apply bg-emblueGreen-light text-emblueStatus-newStatus;
}

.eb-badgeText.eb-badgeText--colors-success-alt {
  @apply bg-emblue-success-hover text-emblue-success;
}

.eb-badgeText.eb-badgeText--colors-often {
  @apply bg-emblue-often-hover text-emblue-often;
}

.eb-badgeText.eb-badgeText--colors-sleepy {
  @apply bg-emblueStatus-asleepStatus text-emblueStatus-asleepStatus;
}

.eb-badgeText.eb-badgeText--colors-sleepy-alt {
  @apply bg-emblue-sleepy-hover text-emblue-sleepy;
}

.eb-badgeText.eb-badgeText--colors-alert {
  @apply bg-emblue-alert-hover text-emblue-alert;
}

.eb-badgeText.eb-badgeText--colors-insecure {
  @apply bg-emblue-insecure-hover text-emblue-insecure;
}

.eb-badgeText.eb-badgeText--colors-inactive {
  @apply bg-emblueStatus-inactiveStatus text-emblueStatus-inactiveStatus;
}

.eb-badgeText.eb-badgeText--colors-frequent {
  @apply bg-emblueStatus-frequentStatus text-emblueStatus-frequentStatus;
}
