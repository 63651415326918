.date-input--container {
  position: relative;
  width: auto;
}

.eb-date-input--multi-dates {
  @apply block leading-5 h-[38px] w-full pl-9 pr-3 py-2 border my-1 text-14 border-emblue-borders rounded-md bg-white placeholder-[#A7B1CC] text-[#111827] focus:outline-none focus:ring-emblue-primary focus:border-emblue-primary;
}

.eb-date-input--multi-dates .eb-multi-dates--div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.date-input--container .react-datepicker-wrapper,
.date-input--container .react-datepicker__input-container {
  width: 100%;
}

.date-input--container .react-datepicker__input-container input {
  @apply block leading-5 w-full pl-10 pr-3 py-2 border text-14 border-emblue-borders rounded-md bg-white placeholder-[#A7B1CC] text-[#111827] focus:outline-none focus:ring-emblue-primary focus:border-emblue-primary;
}
.date-input--container .react-datepicker__input-container input[disabled] {
  @apply !border-emblue-secondary !bg-emblue-secondary-hover !text-emblue-gray;
}

.date-input--container .react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 5px;
}

.date-input--container div#date-input-group:focus-within > div > div > svg path {
  @apply stroke-emblue-primary;
}

.date-input--error {
  @apply text-base text-emblue-alert;
}

.date-input--container div#date-input-group.date-input--container-error svg path {
  @apply stroke-emblue-alert;
}

.date-input--container div#date-input-group.date-input--container-error input {
  @apply border-[#FF4261] text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500;
}

.date-input--container div#date-input-group.readOnly input {
  @apply border-transparent select-none px-0 !important;
}

.react-datepicker {
  padding: 1rem;
  background: #ffffff;
  border: 0px;
  box-shadow: 0px 3px 6px #00000029;
  box-sizing: border-box;
  border-radius: 8px;
}

.react-datepicker__month-container .react-datepicker__header {
  padding-top: 0;
  background-color: #ffffff;
  border-bottom: 0px solid #aeaeae;
}

.react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
  @apply text-emblue-subheadings;
}

.react-datepicker__navigation {
  position: static;
}

.react-datepicker__month-container
  .react-datepicker__header
  .react-datepicker__day-names
  .react-datepicker__day-name {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  @apply text-gray-400;
}

.react-datepicker__month .react-datepicker__week .react-datepicker__day--selected {
  border-radius: 8px;
  @apply bg-emblue-primary;
  color: #ffffff !important;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 8px;
  background-color: transparent;
  color: #000;
}

.react-datepicker__day--in-range {
  border-radius: 0px;
  @apply bg-gray-300;
  color: #fff;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 8px;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover,
.react-datepicker__day--in-range:hover {
  border-radius: 8px;
  @apply bg-emblue-primary-hover;
}

.react-datepicker__day--in-range.react-datepicker__day--range-start,
.react-datepicker__day--in-range.react-datepicker__day--range-end {
  @apply bg-emblue-primary-hover;
}

.react-datepicker__day--in-range.react-datepicker__day--range-start {
  border-radius: 8px 0 0 8px !important;
}

.react-datepicker__day--in-range.react-datepicker__day--range-end {
  border-radius: 0 8px 8px 0 !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: inline-block;
  width: 1.9rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0;
  padding: 0.1rem;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  @apply bg-gray-300;
  border-radius: 0;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-emblue-primary !important;
  color: #ffffff !important;
  border-radius: 8px 0 0 8px !important;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-emblue-primary !important;
  color: #ffffff !important;
  border-radius: 0 8px 8px 0 !important;
}

.react-datepicker-popper {
  z-index: 10;
}
