.card {
  @apply bg-white;
}

.card.secondary {
  @apply bg-emblue-secondary-hover;
}

.card.red {
  @apply bg-[#f87171];
}

.card.yellow {
  @apply bg-[#fcd34d];
}

.card.blue {
  @apply bg-[#004dbc];
}
