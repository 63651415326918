.navBar {
  @apply flex items-center justify-between flex-wrap p-6 bg-emblueBlue;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #6da9e0;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0.2rem;
  opacity: 50;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* Scrollbar personalized */
.visible-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.visible-scrollbar::-webkit-scrollbar-track {
  background-color: #f4f5fa;
}

.visible-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 550px;
  background: #d3ddea;
}

.label {
  @apply block text-14 h-4 font-medium text-emblue-subheadings text-left;
}

.message-error {
  @apply text-base text-emblue-alert w-full text-left;
}

.input-error {
  @apply border-emblue-alert text-emblue-alert placeholder-red-300 focus:border-red-500 focus:ring-red-500 pr-9;
}

/* HTML_UPLOAD STYLE TAGS POPUP */

.tagButton {
  padding: 5px 10px;
  border: 1px solid;
  border-radius: 10px;
  margin: 3px 5px;
  cursor: pointer;
}

.tagActive {
  color: white;
}

/* FIN HTML_UPLOAD STYLE TAGS POPUP */
