@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    @apply appearance-none;
  }
  .indeterminate-progress {
    animation: indeterminate-progress 2s linear infinite;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('./assets/fonts/fonts.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@keyframes indeterminate-progress {
  0% {
    left: -30%;
    width: 30%;
  }
  50% {
    left: 100%;
    width: 30%;
  }
  100% {
    left: 100%;
    width: 30%;
  }
}

* {
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
}

/* Scrollbar personalized */
.visible-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.visible-scrollbar::-webkit-scrollbar-track {
  background-color: #f4f5fa;
}

.visible-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 550px;
  background: #d3ddea;
}

.v2-modal .contacts-body {
  position: absolute;
  z-index: 998;
  top: 65px;
}

.emblue-legacy .contacts-body {
  display: none;
}

.v2-modal ._app__container {
  position: revert !important;
}

.emblue-legacy .top-bar {
  position: absolute;
  z-index: 999;
}

.emblue-legacy #v1-container {
  position: absolute !important;
  z-index: 998;
  top: 0 !important;
  display: block !important;
}

.v2-modal .top-bar {
  position: absolute;
  z-index: 999;
}

.v2-modal #v1-container {
  position: absolute !important;
  z-index: 998;
  top: 0 !important;
  display: block !important;
}

.v2-modal #v1-container-parent {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
#v1-container.modal-iframe {
  width: 100%;
  max-width: 900px;
  height: 60% !important;
  top: 50% !important;
  position: fixed !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

#v1-container-parent.embedded {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 64px;
  left: 0px;
  z-index: 9999999;
  justify-content: center;
  align-items: baseline;
}
#iframe-section {
  overflow: hidden;
}
.container-iframe.fullscreen {
  height: auto !important;
}

body.fullscreen {
  overflow-y: hidden !important;
}
#v1-container.fullscreen {
  width: 100% !important;
  height: calc(100vh - 64px) !important;
  position: fixed !important;
  top: 64px !important;
  left: 0 !important;
  margin: 0px !important;
  z-index: 9999;
}

body.v2-modal {
  overflow: hidden;
}

[data-id='zsalesiq'] {
  top: -5000px;
}

#overlay {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: none;
  background: rgba(0, 0, 0, 0.6);
}

.cv-spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px #ddd solid;
  border-top: 4px #2e93e6 solid;
  border-radius: 50%;
  animation: sp-anime 0.8s infinite linear;
}

@keyframes sp-anime {
  100% {
    transform: rotate(360deg);
  }
}

.is-hide {
  display: none;
}

body.v2-modal-campaign {
  overflow: hidden;
}

body.v2-modal-campaign #v1-container-parent {
  position: absolute !important;
  z-index: 99999;
  top: 0 !important;
  left: 0 !important;
  display: block !important;
  width: 100% !important;
  height: 100% !important;
}

body.v2-modal-campaign #v1-container-parent #v1-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  position: absolute;
  overflow: hidden;
}
