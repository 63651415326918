.eb-icon-svg svg {
  height: 100%;
  width: 100%;
  min-width: 100%;
}

/** fill colors */
.eb-icon-svg.eb-icon-svg--fill-primary svg path,
.eb-icon-svg.eb-icon-svg--fill-primary svg circle {
  @apply fill-emblue-primary;
}

.eb-icon-svg.eb-icon-svg--fill-secondary svg path,
.eb-icon-svg.eb-icon-svg--fill-secondary svg circle {
  @apply fill-emblue-black;
}

.eb-icon-svg.eb-icon-svg--fill-tertiary svg path,
.eb-icon-svg.eb-icon-svg--fill-tertiary svg circle {
  @apply fill-emblue-orange;
}

.eb-icon-svg.eb-icon-svg--fill-white svg path,
.eb-icon-svg.eb-icon-svg--fill-white svg circle {
  @apply fill-emblue-white;
}

.eb-icon-svg.eb-icon-svg--fill-success svg path,
.eb-icon-svg.eb-icon-svg--fill-success svg circle {
  @apply fill-emblue-success;
}

.eb-icon-svg.eb-icon-svg--fill-often svg path,
.eb-icon-svg.eb-icon-svg--fill-often svg circle {
  @apply fill-emblue-often;
}

.eb-icon-svg.eb-icon-svg--fill-sleepy svg path,
.eb-icon-svg.eb-icon-svg--fill-sleepy svg circle {
  @apply fill-emblue-sleepy;
}

.eb-icon-svg.eb-icon-svg--fill-destructive svg path,
.eb-icon-svg.eb-icon-svg--fill-destructive svg circle {
  @apply fill-emblue-alert;
}

.eb-icon-svg.eb-icon-svg--fill-insecure svg path,
.eb-icon-svg.eb-icon-svg--fill-insecure svg circle {
  @apply fill-emblue-insecure;
}

.eb-icon-svg.eb-icon-svg--fill-disabled svg path,
.eb-icon-svg.eb-icon-svg--fill-disabled svg circle {
  @apply fill-emblue-disabled;
}

.eb-icon-svg.eb-icon-svg--fill-gray svg path,
.eb-icon-svg.eb-icon-svg--fill-gray svg circle {
  @apply fill-emblue-gray;
}

.eb-icon-svg.eb-icon-svg--fill-gray-dark svg path,
.eb-icon-svg.eb-icon-svg--fill-gray-dark svg circle {
  @apply fill-emblue-gray-dark;
}

.eb-icon-svg.eb-icon-svg--fill-passive svg path,
.eb-icon-svg.eb-icon-svg--fill-passive svg circle {
  @apply fill-emblue-passive;
}

.eb-icon-svg.eb-icon-svg--fill-greenLighter svg path,
.eb-icon-svg.eb-icon-svg--fill-greenLighter svg circle {
  @apply fill-emblue-green-lighter;
}

.eb-icon-svg.eb-icon-svg--fill-greenTeal svg path,
.eb-icon-svg.eb-icon-svg--fill-greenTeal svg circle {
  @apply fill-emblue-green-teal;
}

.eb-icon-svg.eb-icon-svg--fill-greenLight svg path,
.eb-icon-svg.eb-icon-svg--fill-greenLight svg circle {
  @apply fill-emblue-green-light;
}

.eb-icon-svg.eb-icon-svg--fill-skyBlue svg path,
.eb-icon-svg.eb-icon-svg--fill-skyBlue svg circle {
  @apply fill-emblue-sky-blue;
}

/** stroke colors */
.eb-icon-svg.eb-icon-svg--stroke-primary svg path,
.eb-icon-svg.eb-icon-svg--stroke-primary svg circle {
  @apply stroke-emblue-primary;
}

.eb-icon-svg.eb-icon-svg--stroke-secondary svg path,
.eb-icon-svg.eb-icon-svg--stroke-secondary svg circle {
  @apply stroke-emblue-black;
}

.eb-icon-svg.eb-icon-svg--stroke-tertiary svg path,
.eb-icon-svg.eb-icon-svg--stroke-tertiary svg circle {
  @apply stroke-emblue-orange;
}

.eb-icon-svg.eb-icon-svg--stroke-destructive svg path,
.eb-icon-svg.eb-icon-svg--stroke-destructive svg circle {
  @apply stroke-emblue-alert;
}

.eb-icon-svg.eb-icon-svg--stroke-white svg path,
.eb-icon-svg.eb-icon-svg--stroke-white svg circle {
  @apply stroke-emblue-white;
}

.eb-icon-svg.eb-icon-svg--stroke-success svg path,
.eb-icon-svg.eb-icon-svg--stroke-success svg circle {
  @apply stroke-emblue-success;
}

.eb-icon-svg.eb-icon-svg--stroke-often svg path,
.eb-icon-svg.eb-icon-svg--stroke-often svg circle {
  @apply stroke-emblue-often;
}

.eb-icon-svg.eb-icon-svg--stroke-sleepy svg path,
.eb-icon-svg.eb-icon-svg--stroke-sleepy svg circle {
  @apply stroke-emblue-sleepy;
}

.eb-icon-svg.eb-icon-svg--stroke-insecure svg path,
.eb-icon-svg.eb-icon-svg--stroke-insecure svg circle {
  @apply stroke-emblue-insecure;
}

.eb-icon-svg.eb-icon-svg--stroke-disabled svg path,
.eb-icon-svg.eb-icon-svg--stroke-disabled svg circle {
  @apply stroke-emblue-disabled;
}

.eb-icon-svg.eb-icon-svg--stroke-gray svg path,
.eb-icon-svg.eb-icon-svg--stroke-gray svg circle {
  @apply stroke-emblue-gray;
}

/** bg colors rounded*/
.eb-icon-svg.eb-icon-svg--bg-color-primary {
  @apply bg-emblue-primary-light;
}

/** bg colors rounded*/
.eb-icon-svg.eb-icon-svg--bg-color-primary-dark {
  @apply bg-emblue-primary;
}

.eb-icon-svg.eb-icon-svg--bg-color-secondary {
  @apply bg-emblue-secondary;
}

/* This color is being discussed with UX to modify it or add it to the color palette  */
.eb-icon-svg.eb-icon-svg--bg-color-tertiary {
  @apply bg-[#FFE5CC];
}

.eb-icon-svg.eb-icon-svg--bg-color-success {
  @apply bg-emblue-success-hover;
}

.eb-icon-svg.eb-icon-svg--bg-color-often {
  @apply bg-emblue-often-hover;
}

.eb-icon-svg.eb-icon-svg--bg-color-sleepy {
  @apply bg-emblue-sleepy-hover;
}

.eb-icon-svg.eb-icon-svg--bg-color-destructive {
  @apply bg-emblue-alert-hover;
}

.eb-icon-svg.eb-icon-svg--bg-color-insecure {
  @apply bg-emblue-insecure-hover;
}

.eb-icon-svg.eb-icon-svg--bg-color-disabled {
  @apply bg-emblue-disabled;
}

.eb-icon-svg.eb-icon-svg--bg-color-gray {
  @apply bg-emblue-gray;
}
.eb-icon-svg.eb-icon-svg--bg-color-gray-light {
  @apply bg-emblue-gray-light-preview;
}

.eb-icon-svg.eb-icon-svg--bg-color-transparent {
  background-color: transparent;
}
.eb-icon-svg.eb-icon-svg--bg-color-headingIcon {
  @apply bg-emblue-gray-light;
}

.eb-icon-svg.eb-icon-svg--bg-color-passiveHover {
  @apply bg-emblue-passive-hover;
}

.eb-icon-svg.eb-icon-svg--bg-color-greenLighter {
  @apply bg-emblue-green-lighter;
}

.eb-icon-svg.eb-icon-svg--bg-color-greenLight {
  @apply bg-emblue-green-light;
}

.eb-icon-svg.eb-icon-svg--bg-color-greenTeal {
  @apply bg-emblue-green-teal;
}

.eb-icon-svg.eb-icon-svg--bg-color-skyBlue {
  @apply bg-emblue-sky-blue;
}

.eb-icon-svg.eb-icon-svg--bg-color-purpleLight {
  @apply bg-emblue-purple-light;
}

#fill-white {
  @apply fill-emblue-white;
}

.eb-icon-svg.eb-icon-svg--fill-inactive svg path,
.eb-icon-svg.eb-icon-svg--fill-inactive svg circle {
  @apply fill-emblue-inactive-status_new;
}

.eb-icon-svg.eb-icon-svg--stroke-inactive svg path,
.eb-icon-svg.eb-icon-svg--stroke-inactive svg circle {
  @apply stroke-emblue-inactive-status_new;
}

.eb-icon-svg.eb-icon-svg--bg-color-inactive {
  @apply bg-emblueStatus-inactiveStatus;
}

.eb-icon-svg.eb-icon-svg--fill-frequent svg path,
.eb-icon-svg.eb-icon-svg--fill-frequent svg circle {
  @apply fill-emblue-frequent-status_new;
}

.eb-icon-svg.eb-icon-svg--stroke-frequent svg path,
.eb-icon-svg.eb-icon-svg--stroke-frequent svg circle {
  @apply stroke-emblue-frequent-status_new;
}

.eb-icon-svg.eb-icon-svg--bg-color-frequent {
  @apply bg-emblueStatus-frequentStatus;
}

.eb-icon-svg.eb-icon-svg--fill-success_new svg path,
.eb-icon-svg.eb-icon-svg--fill-success_new svg circle {
  @apply fill-emblue-success-status-new;
}

.eb-icon-svg.eb-icon-svg--stroke-success_new svg path,
.eb-icon-svg.eb-icon-svg--stroke-success_new svg circle {
  @apply stroke-emblue-success-status-new;
}

.eb-icon-svg.eb-icon-svg--bg-color-success_new {
  @apply bg-emblueGreen-light;
}
