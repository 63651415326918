.eb-text-area {
  @apply block leading-5 w-full px-3 py-2 border my-1 text-14 border-emblue-borders rounded-md bg-white placeholder-[#A7B1CC] text-[#111827] focus:outline-none focus:ring-emblue-primary focus:border-emblue-primary;
  white-space: pre-wrap;
}

.eb-text-area[disabled] {
  @apply !border-emblue-secondary !bg-emblue-secondary-hover !text-emblue-gray;
}

.eb-text-area.eb-text-area--error {
  @apply border-emblue-alert text-emblue-alert placeholder-red-300 focus:border-red-500 focus:ring-red-500 pr-9;
}
