.eb-card {
  @apply relative bg-white w-full rounded-lg border flex flex-col !important;
}

.eb-card.eb-card-primary > .eb-card-footer.eb-card--footer-gray,
.eb-card.eb-card-secondary > .eb-card-footer.eb-card--footer-gray,
.eb-card.eb-card-tertiary > .eb-card-footer.eb-card--footer-gray,
.eb-card.eb-card-destructive > .eb-card-footer.eb-card--footer-gray,
.eb-card.eb-card-gray > .eb-card-footer.eb-card--footer-gray {
  @apply bg-transparent;
}

.eb-card.eb-card-primary:not(.eb-card-footer),
.eb-card.eb-card-primary > .eb-card-footer:not(.eb-card--footer-transparent) .eb-card-section {
  @apply bg-emblue-primary;
}

.eb-card.eb-card-secondary:not(.eb-card-footer),
.eb-card.eb-card-secondary > .eb-card-footer:not(.eb-card--footer-transparent) .eb-card-section {
  @apply bg-emblue-secondary;
}

.eb-card.eb-card-tertiary:not(.eb-card-footer),
.eb-card.eb-card-tertiary > .eb-card-footer:not(.eb-card--footer-transparent) .eb-card-section {
  @apply bg-emblue-tertiary;
}

.eb-card.eb-card-destructive:not(.eb-card-footer),
.eb-card.eb-card-destructive > .eb-card-footer:not(.eb-card--footer-transparent) .eb-card-section {
  @apply bg-emblue-alert;
}

.eb-card.eb-card-gray:not(.eb-card-footer),
.eb-card.eb-card-gray > .eb-card-footer:not(.eb-card--footer-transparent) .eb-card-section {
  @apply bg-[#F9FAFB];
}

.eb-card.eb-white-content h1,
.eb-card.eb-white-content h2,
.eb-card.eb-white-content h3,
.eb-card.eb-white-content h3,
.eb-card.eb-white-content h4,
.eb-card.eb-white-content h5,
.eb-card.eb-white-content h6,
.eb-card.eb-white-content p,
.eb-card.eb-white-content label,
.eb-card.eb-white-content span {
  @apply text-emblue-white;
}

.eb-card.card-shadow {
  box-shadow: 0px 1px 3px #00000029;
}

.eb-card .eb-card--footer-gray {
  @apply bg-[#F9FAFB];
}

.eb-card .eb-card--footer-transparent {
  @apply bg-transparent;
}

.eb-card .eb-card--footer-white {
  @apply bg-white;
}

.eb-card .eb-card-section {
  @apply relative w-full rounded-tl-lg rounded-tr-lg;
}

.eb-card .eb-card-footer {
  @apply rounded-bl-lg rounded-br-lg;
}
