.eb-input {
  @apply relative w-full;
}

.eb-input .eb-input--input {
  @apply block leading-5 w-full px-3 py-2 border my-1 text-14 border-emblue-borders rounded-md bg-white placeholder-[#A7B1CC] text-[#111827] focus:outline-none focus:ring-emblue-primary focus:border-emblue-primary;
  height: 38px;
}
.eb-input .eb-input--standard {
  @apply focus:!border-emblue-borders bg-[#FAFBFC];
}

.eb-input .eb-input--input::-ms-reveal,
.eb-input .eb-input--input::-ms-clear {
  display: none;
}

.eb-input .eb-input--error {
  @apply border-emblue-alert text-emblue-alert placeholder-red-300 focus:border-red-500 focus:ring-red-500 pr-9;
}

.eb-input .eb-input--message-error {
  @apply text-base text-emblue-alert;
}

.eb-input .eb-input--message-info {
  @apply text-base text-emblue-borders;
}

.disabled {
  @apply !border-emblue-secondary !bg-emblue-secondary-hover !text-emblue-gray;
}

.eb-input .seeMore {
  @apply truncate;
}

.eb-input .readOnly {
  @apply border-transparent select-none px-0 !important;
}
