.eb-badge-color-primary {
  @apply bg-emblue-primary-light text-emblue-primary;
}

.eb-badge-color-success {
  @apply bg-emblue-success-hover text-emblue-success;
}

.eb-badge-color-sleepy {
  @apply bg-emblue-sleepy-hover text-emblue-sleepy;
}

.eb-badge-color-often {
  @apply bg-emblue-often-hover text-emblue-often;
}

.eb-badge-color-alert {
  @apply bg-emblue-alert-hover text-emblue-alert;
}

.eb-badge-color-insecure {
  @apply bg-emblue-insecure-hover text-emblue-insecure;
}
